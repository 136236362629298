export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"FoxTailBox - сервис по проверке HardSkills разработчиков"},{"property":"og:description","content":"FoxTailBox - сервис по проверке HardSkills разработчиков"},{"property":"og:title","content":"FoxTailBox"},{"property":"og:image","content":"/preview.png"},{"name":"twitter:image","content":"/preview.png"},{"name":"twitter:card","content":"summary_large_image"}],"link":[],"style":[],"script":[],"noscript":[],"title":"FoxTailBox"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null