<script setup lang="ts">
const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div class="flex w-full flex-col items-center justify-center gap-7 px-5 py-10 md:px-0">
    <img src="/images/404.svg" alt="404" />
    <div class="flex max-w-[400px] flex-col items-center text-center">
      <p class="mb-4 text-headline font-bold">Страница не найдена</p>
      <p class="mb-1 leading-5">
        Ссылка, по которой вы перешли, недоступна. Возможно, страница по этой ссылке была перемещена
        или удалена.
      </p>
      <p class="leading-5">
        Пожалуйста, проверьте правильность написания URL-адреса. Если вы уверены, что адрес введен
        верно, сообщите о проблеме на почту
        <a class="hover:underline" href="mailto:support@foxtailbox.com"> support@foxtailbox.com.</a>
      </p>
      <Button class="mt-6 px-5 py-3" @click="handleError">Вернуться на главную</Button>
    </div>
  </div>
</template>

<style scoped></style>
