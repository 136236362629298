<script setup lang="ts">
import { IHeaderLink } from '~/components/infrastructure/header/types'
import { NuxtLink } from '#components'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

defineProps<{
  links: IHeaderLink[]
}>()

// Composable
const userStore = useUserStore()
const { isAuthorized, isAdmin } = storeToRefs(userStore)

// Actions
const logOut = async () => {
  await userStore.logOut()
  await navigateTo({ name: 'auth' })
}
</script>

<template>
  <div>
    <NuxtLink v-if="!isAuthorized" :to="{ name: 'auth' }" class="font-bold text-madras">
      Войти
    </NuxtLink>
    <Menu v-else as="div" class="relative inline-block text-left">
      <div>
        <MenuButton class="font-bold text-madras"> Меню </MenuButton>
      </div>

      <Transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute -right-3 mt-5 w-48 origin-top-right divide-y divide-gray-100 rounded-b-lg bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none lg:-right-14"
        >
          <div class="flex flex-col pt-1 text-mine-shaft">
            <div v-if="isAdmin" class="border-b border-gray-400">
              <div class="my-2 flex items-center justify-center gap-1.5 px-2">
                <Icon name="mdi:settings" class="text-sm" />
                <p class="text-center font-bold">Администрирование</p>
              </div>

              <div>
                <NuxtLink
                  :to="{ name: 'admin-users' }"
                  class="flex w-full duration-300 hover:bg-[#F1F1F1]"
                >
                  <MenuItem v-slot="{ active }" as="button" class="w-full px-3 py-2 text-left">
                    <span :class="[active ? '' : 'text-gray-900']"> Пользователи </span>
                  </MenuItem>
                </NuxtLink>
                <NuxtLink
                  :to="{ name: 'admin-evaluations' }"
                  class="flex w-full duration-300 hover:bg-[#F1F1F1]"
                >
                  <MenuItem v-slot="{ active }" as="button" class="w-full px-3 py-2 text-left">
                    <span :class="[active ? '' : 'text-gray-900']"> Оценки </span>
                  </MenuItem>
                </NuxtLink>
              </div>
            </div>

            <NuxtLink :to="{ name: 'user' }">
              <MenuItem
                v-slot="{ active }"
                as="button"
                class="w-full px-3 py-3 text-left duration-300 hover:bg-[#F1F1F1]"
              >
                <span :class="[active ? '' : 'text-gray-900']"> Личный кабинет </span>
              </MenuItem>
            </NuxtLink>
            <MenuItem
              v-slot="{ active }"
              as="button"
              class="w-full px-3 py-3 text-left duration-300 hover:bg-[#F1F1F1]"
              @click="logOut"
            >
              <span :class="[active ? '' : 'text-gray-900']"> Выйти </span>
            </MenuItem>
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  </div>
</template>
