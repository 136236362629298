import {
  IConfirmPasswordResetForm,
  ISignInForm,
  ISignInResponse,
  ISignUpForm,
  ISignUpResponse,
  IUpdateSelfForm,
  IUser,
} from '~/domain/user'
import { IResponse } from '~/domain/base'

const serializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v),
}

export const useUserStore = defineStore('user', () => {
  const user = useLocalStorage<IUser | null>('user', null, { serializer })
  //@TODO localStorage не безопасно, нужно использовать httpOnly cookie с сервера
  const accessToken = useLocalStorage<null | string>('accessToken', null, { serializer })
  const isAuthorized = computed(() => !!user?.value)
  const isFeedbackComplete = computed(() => user.value?.is_feedback_complete)

  const getRole = computed(() => user.value?.roles)
  const isAdmin = computed(() => getRole.value?.name === 'SuperAdmin')

  /**
   * Авторизация в системе
   */
  const signIn = async (form: ISignInForm) => {
    const { data } = await useApi<IResponse<ISignInResponse>>('/auth/sign-in', {
      body: form,
      method: 'POST',
      disableErrorHandling: true,
    })

    if (data) {
      accessToken.value = data.access_token
      await fetchAuthUser()
    }

    return data
  }

  /**
   * Выслать код для регистрации
   */
  const sendSignUpCode = (email: string) => {
    return useApi<ISignUpResponse>('/auth/sign-up/send-code', {
      body: {
        email,
      },
      method: 'POST',
    })
  }

  /**
   * Подтверждение регистрации
   */
  const signUp = async (form: ISignUpForm) => {
    const { data } = await useApi<IResponse<ISignUpResponse>>('/auth/sign-up', {
      body: {
        ...form,
        password_confirmation: form.password,
      },
      method: 'POST',
    })

    if (data) {
      accessToken.value = data.access_token

      await fetchAuthUser()
    }

    return data
  }

  const sendRestorePasswordLink = async (email: string) => {
    return useApi('/auth/password-reset/send-code', {
      body: {
        email,
      },
      method: 'POST',
      disableErrorHandling: true,
    })
  }

  const confirmRestorePassword = async (form: IConfirmPasswordResetForm) => {
    return useApi('/auth/password-reset', {
      body: form,
      method: 'POST',
      disableErrorHandling: true,
    })
  }

  const fetchAuthUser = async () => {
    const { data } = await useApi<IResponse<IUser>>('/auth')

    if (data) {
      user.value = data
    }

    return data
  }

  const updateAuthUser = async (form: IUpdateSelfForm) => {
    const { data } = await useApi<IResponse<IUser>>(`/users/${user.value?.id}`, {
      body: form,
      method: 'PUT',
    })

    if (data) {
      user.value = data
    }

    return data
  }

  const deleteAuthUser = async () => useApi(`/users/${user.value?.id}`, { method: 'DELETE' })

  const logOutWithoutApi = () => {
    user.value = null
    accessToken.value = null
  }

  const logOut = async () => {
    try {
      await useApi('/auth/logout', { method: 'POST', disableErrorHandling: true })
      user.value = null
      accessToken.value = null
    } catch (e) {
      user.value = null
      accessToken.value = null
    }
  }

  return {
    user,
    accessToken,
    isAuthorized,
    isAdmin,
    isFeedbackComplete,
    signIn,
    sendSignUpCode,
    signUp,
    sendRestorePasswordLink,
    confirmRestorePassword,
    fetchAuthUser,
    updateAuthUser,
    deleteAuthUser,
    logOutWithoutApi,
    logOut,
  }
})
