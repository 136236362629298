<script lang="ts" setup>
/**
 * Одноэтажные модальные окна для приложения
 */
import { IModalType, ModalProviderKey } from '~/components/infrastructure/modal/types'
import type { Component } from 'vue'

const route = useRoute()
const currentPath = computed(() => route.path)
const currentQuery = computed(() => route.query)

watch(currentPath, () => clearModal())
watch(currentQuery, () => clearModal())

const initialComponent = 'span'

// Data
let isShow = ref(false)
let props = ref<any>(null)
let component = shallowRef<string | Component>('span')
let type = ref<IModalType>('adaptive')
let closeCb: ((data: any) => void) | null,
  okCb: ((data: any) => void) | null,
  dismissCb: ((data: any) => void) | null

// Actions
const clearModal = async () => {
  isShow.value = false
  closeCb = null
  okCb = null
  dismissCb = null
  setTimeout(() => {
    component.value = initialComponent
    props.value = null
    type.value = 'adaptive'
  }, 300)
}

const onShow = async (data: Component | Promise<{ default: Component }>) => {
  const result = await data
  component.value = 'default' in result ? result.default : result
  await nextTick(() => {
    isShow.value = true
  })
}

const onClose = (data: any) => {
  closeCb?.(data)
  clearModal()
}

const onOk = (data: any) => {
  okCb?.(data)
  clearModal()
}

const onDismiss = (data: any) => {
  dismissCb?.(data)
  clearModal()
}

// Providers
provide(ModalProviderKey, {
  show: (c, params) => {
    props.value = params?.props
    type.value = params?.type ?? 'adaptive'
    onShow(c)

    const API = {
      onClose: (cb?: any) => {
        closeCb = cb
        return API
      },
      onOk: (cb?: any) => {
        okCb = cb
        return API
      },
      onDismiss: (cb?: any) => {
        dismissCb = cb
        return API
      },
    }

    return API
  },
})
</script>

<template>
  <slot />

  <ClientOnly>
    <ModalWrapper v-model="isShow" :type="type" @close="onClose" @dismiss="onDismiss" @ok="onOk">
      <Component :is="component" v-bind="props" />
    </ModalWrapper>
  </ClientOnly>
</template>
