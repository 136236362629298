<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { NuxtLink } from '#components'
import { IHeaderLink } from '~/components/infrastructure/header/types'

// Data
const items: IHeaderLink[] = [
  { title: 'О сервисе', to: 'about' },
  { title: 'Выгода', to: 'economy' },
  { title: 'Тарифы', to: 'price' },
]
</script>

<template>
  <Disclosure
    v-slot="{ open, close }"
    as="nav"
    class="fixed top-0 z-20 w-full max-w-[1366px]"
    v-bind="$attrs"
  >
    <div
      class="relative z-10 flex h-16 items-center justify-between bg-black-squeeze px-4 py-3 md:rounded-b-2xl lg:px-14"
      :class="[{ 'shadow-header': !open }]"
    >
      <NuxtLink :to="{ name: 'index' }" class="cursor-pointer">
        <img
          src="/logo.svg"
          alt="Логотип FoxTailBox (Фокстейл бокс)"
          style="width: 158px; height: 37px"
          class="pointer-events-none"
        />
      </NuxtLink>

      <nav class="hidden items-center gap-10 md:flex">
        <NuxtLink
          v-for="link of items"
          :key="link.title"
          :to="{ name: 'index', query: { to: link.to } }"
          class="font-bold text-madras"
        >
          {{ link.title }}
        </NuxtLink>
        <NuxtLink :to="{ name: 'evaluation' }">
          <Button class="px-9 py-2"> Оценить код </Button>
        </NuxtLink>

        <HeaderMenu :links="items" />
      </nav>

      <DisclosureButton class="flex items-center text-[30px] md:hidden">
        <span class="sr-only">Открыть меню</span>
        <AnimateTransition enter-name="zoomIn">
          <Icon v-if="!open" name="mdi:menu" />
          <Icon v-else name="mdi:close" />
        </AnimateTransition>
      </DisclosureButton>
    </div>

    <Transition
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="transform -translate-y-full opacity-0"
      enter-to-class="transform translate-y-0 opacity-100"
      leave-active-class="transition duration-300 ease-out"
      leave-from-class="transform translate-y-0 opacity-100"
      leave-to-class="transform -translate-y-full opacity-0"
    >
      <DisclosurePanel
        class="shadow-bottom rounded-b-lg bg-white px-8 py-2 text-mobile shadow-md lg:hidden"
      >
        <div class="flex flex-col items-start">
          <NuxtLink :to="{ name: 'evaluation' }" class="w-full" @click="close">
            <DisclosureButton type="button" class="w-full">
              <Button size="sm" class="w-full py-3"> Оценить код </Button>
            </DisclosureButton>
          </NuxtLink>
          <NuxtLink
            v-for="item of items"
            :key="item.title"
            :to="{ name: 'index', query: { to: item.to } }"
            class="flex w-full"
            @click="close"
          >
            <DisclosureButton class="w-full py-3 pl-4 text-mine-shaft">
              <div class="flex items-center gap-3">
                {{ item.title }}
              </div>
            </DisclosureButton>
          </NuxtLink>

          <HeaderMenuMobile :links="items" :close="close" />
        </div>
      </DisclosurePanel>
    </Transition>
  </Disclosure>
</template>
