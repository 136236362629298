/**
 * Яндекс метрика для сервиса
 * Будет подгружена, только если в env стоит переменная NUXT_PUBLIC_YANDEX_METRIKA_ID
 */
export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig()
  const yandexMetrikaId = config.public.yandexMetrikaId
  const router = useRouter()

  if (yandexMetrikaId) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const VueYandexMetrika: any = await import('vue3-yandex-metrika')

    nuxtApp.vueApp.use(VueYandexMetrika.default, {
      id: yandexMetrikaId,
      router,
      env: process.env.NODE_ENV,
    })
  }
})
