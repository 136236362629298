<script setup lang="ts">
const title = 'FoxTailBox'
const description = 'FoxTailBox - сервис по проверке HardSkills разработчиков'
const host = useHost()

useHead(() => ({
  htmlAttrs: {
    lang: 'ru',
  },
}))

useSeoMeta({
  title,
  titleTemplate: (name) =>
    name ? `${name} | FoxTailBox` : 'FoxTailBox - сервис по проверке HardSkills разработчиков',
  ogTitle: title,
  description: description,
  ogDescription: description,
  ogImage: `/preview.png`,
  twitterImage: `/preview.png`,
  twitterCard: 'summary_large_image',
  keywords:
    'FoxTailBox, Фокстейл бокс, Технический скрининг, HR, Найм, Рекрутинг, Рекрутмент, Предквалификация, Технический уровень, Автоматическая проверка кода',
})

useSchemaOrg([
  defineOrganization({
    name: 'FoxTailBox',
    founder: {
      '@type': 'Person',
      email: 'igor.prokofev@foxtail.cc',
    },
    description:
      'Сервис по проверке HardSkills разработчиков. Пользователь загружает ссылку на публичный репозиторий, где оцениваются параметры кода, включая читабельность, поддерживаемость, оптимальность алгоритма, документацию и покрытие тестами.',
    url: `${host}`,
    logo: `${host}/logo.svg`,
    // sameAs: [
    //   'http://www.facebook.com/foxtailbox',
    //   'http://www.twitter.com/foxtailbox',
    //   'http://www.instagram.com/foxtailbox',
    // ],
  }),
  defineWebSite({
    name: 'FoxTailBox',
    url: `${host}`,
  }),
  {
    '@context': 'https://schema.org',
    '@type': 'Service',
    name: 'Проверка кода разработчика',
    provider: {
      '@type': 'Organization',
      name: 'FoxTailBox',
    },
    offers: [
      {
        '@type': 'Offer',
        priceCurrency: 'RUB',
        availability: 'https://schema.org/InStock',
        price: '99',
        description: '99 руб за проверку одного репозитория c помощью машинного обучения',
        url: `${host}`,
      },
      {
        '@type': 'Offer',
        priceCurrency: 'RUB',
        availability: 'https://schema.org/InStock',
        price: '3500',
        description: '3500 руб/час за привлечение нашего эксперта',
        url: `${host}`,
      },
    ],
  },
  {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: 'Проверка одного репозитория c помощью машинного обучения',
    description:
      'Алгоритмы ML обучены проверять код разработчика на соответствие заявленному уровню',
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      price: '99',
      priceCurrency: 'RUB',
    },
    image: `${host}/images/landing/languages.svg`,
  },
  {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: 'Проверка разработчика через техническое интервью',
    description: 'Привлечение нашего эксперта для вашего кандидата',
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      price: '3500',
      priceCurrency: 'RUB',
    },
    image: `${host}/images/landing/languages.svg`,
  },
])
</script>

<template>
  <div class="flex min-h-screen flex-col items-center">
    <Header />
    <div class="flex w-full max-w-[1366px] flex-grow pb-10 pt-16">
      <slot />
    </div>
    <Footer />
  </div>
</template>
