<script setup lang="ts">
import Layout from '~/layouts/default.vue'
import { Errors400, Errors404, Errors403, ErrorsDefault } from '#components'

const error = useError()

const status = computed(() => error.value?.statusCode)

const component = computed(() => {
  switch (status.value) {
    case 400:
      return Errors400
    case 404:
      return Errors404
    case 403:
      return Errors403
    case 500:
      return ErrorsDefault
  }
})

const isShow = ref(false)
onMounted(() => setTimeout(() => (isShow.value = true), 200))
</script>

<template>
  <Layout v-if="component">
    <Transition appear as="template" enter-active-class="animate__animated animate__headShake">
      <Component :is="component" v-show="isShow" />
    </Transition>
  </Layout>
</template>
