import { default as evaluationsavIQEDMFfBMeta } from "/projects/frontend/src/pages/admin/evaluations.vue?macro=true";
import { default as usersxhPDfqZXsRMeta } from "/projects/frontend/src/pages/admin/users.vue?macro=true";
import { default as confirm_45change_45passwordO2bELJ4LRFMeta } from "/projects/frontend/src/pages/auth/confirm-change-password.vue?macro=true";
import { default as confirmFE8Sih24OQMeta } from "/projects/frontend/src/pages/auth/confirm.vue?macro=true";
import { default as indexNpKrIssEx1Meta } from "/projects/frontend/src/pages/auth/index.vue?macro=true";
import { default as request_45change_45passwordu3qSu7h0vKMeta } from "/projects/frontend/src/pages/auth/request-change-password.vue?macro=true";
import { default as sign_45upZXqancN5soMeta } from "/projects/frontend/src/pages/auth/sign-up.vue?macro=true";
import { default as success_45send_45passwordmpcpXIeYrkMeta } from "/projects/frontend/src/pages/auth/success-send-password.vue?macro=true";
import { default as success_45sendA8JMlKb7upMeta } from "/projects/frontend/src/pages/auth/success-send.vue?macro=true";
import { default as authlzvdI7r9k7Meta } from "/projects/frontend/src/pages/auth.vue?macro=true";
import { default as index0wQsjFcazOMeta } from "/projects/frontend/src/pages/docs/index.vue?macro=true";
import { default as indexmcUy4IGQJzMeta } from "/projects/frontend/src/pages/evaluation/[id]/index.vue?macro=true";
import { default as confirmHKzKHpKihLMeta } from "/projects/frontend/src/pages/evaluation/confirm.vue?macro=true";
import { default as historySxSQcYM4Y3Meta } from "/projects/frontend/src/pages/evaluation/history.vue?macro=true";
import { default as indexbQQ6z7cmgYMeta } from "/projects/frontend/src/pages/evaluation/index.vue?macro=true";
import { default as evaluationvzGEGtRvB2Meta } from "/projects/frontend/src/pages/evaluation.vue?macro=true";
import { default as indexovMrFcLKw4Meta } from "/projects/frontend/src/pages/index.vue?macro=true";
import { default as indexlGgmNHQ6aqMeta } from "/projects/frontend/src/pages/user/index.vue?macro=true";
export default [
  {
    name: evaluationsavIQEDMFfBMeta?.name ?? "admin-evaluations",
    path: evaluationsavIQEDMFfBMeta?.path ?? "/admin/evaluations",
    meta: evaluationsavIQEDMFfBMeta || {},
    alias: evaluationsavIQEDMFfBMeta?.alias || [],
    redirect: evaluationsavIQEDMFfBMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/admin/evaluations.vue").then(m => m.default || m)
  },
  {
    name: usersxhPDfqZXsRMeta?.name ?? "admin-users",
    path: usersxhPDfqZXsRMeta?.path ?? "/admin/users",
    meta: usersxhPDfqZXsRMeta || {},
    alias: usersxhPDfqZXsRMeta?.alias || [],
    redirect: usersxhPDfqZXsRMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    path: authlzvdI7r9k7Meta?.path ?? "/auth",
    children: [
  {
    name: confirm_45change_45passwordO2bELJ4LRFMeta?.name ?? "auth-confirm-change-password",
    path: confirm_45change_45passwordO2bELJ4LRFMeta?.path ?? "confirm-change-password",
    meta: confirm_45change_45passwordO2bELJ4LRFMeta || {},
    alias: confirm_45change_45passwordO2bELJ4LRFMeta?.alias || [],
    redirect: confirm_45change_45passwordO2bELJ4LRFMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/auth/confirm-change-password.vue").then(m => m.default || m)
  },
  {
    name: confirmFE8Sih24OQMeta?.name ?? "auth-confirm",
    path: confirmFE8Sih24OQMeta?.path ?? "confirm",
    meta: confirmFE8Sih24OQMeta || {},
    alias: confirmFE8Sih24OQMeta?.alias || [],
    redirect: confirmFE8Sih24OQMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/auth/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexNpKrIssEx1Meta?.name ?? "auth",
    path: indexNpKrIssEx1Meta?.path ?? "",
    meta: indexNpKrIssEx1Meta || {},
    alias: indexNpKrIssEx1Meta?.alias || [],
    redirect: indexNpKrIssEx1Meta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: request_45change_45passwordu3qSu7h0vKMeta?.name ?? "auth-request-change-password",
    path: request_45change_45passwordu3qSu7h0vKMeta?.path ?? "request-change-password",
    meta: request_45change_45passwordu3qSu7h0vKMeta || {},
    alias: request_45change_45passwordu3qSu7h0vKMeta?.alias || [],
    redirect: request_45change_45passwordu3qSu7h0vKMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/auth/request-change-password.vue").then(m => m.default || m)
  },
  {
    name: sign_45upZXqancN5soMeta?.name ?? "auth-sign-up",
    path: sign_45upZXqancN5soMeta?.path ?? "sign-up",
    meta: sign_45upZXqancN5soMeta || {},
    alias: sign_45upZXqancN5soMeta?.alias || [],
    redirect: sign_45upZXqancN5soMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: success_45send_45passwordmpcpXIeYrkMeta?.name ?? "auth-success-send-password",
    path: success_45send_45passwordmpcpXIeYrkMeta?.path ?? "success-send-password",
    meta: success_45send_45passwordmpcpXIeYrkMeta || {},
    alias: success_45send_45passwordmpcpXIeYrkMeta?.alias || [],
    redirect: success_45send_45passwordmpcpXIeYrkMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/auth/success-send-password.vue").then(m => m.default || m)
  },
  {
    name: success_45sendA8JMlKb7upMeta?.name ?? "auth-success-send",
    path: success_45sendA8JMlKb7upMeta?.path ?? "success-send",
    meta: success_45sendA8JMlKb7upMeta || {},
    alias: success_45sendA8JMlKb7upMeta?.alias || [],
    redirect: success_45sendA8JMlKb7upMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/auth/success-send.vue").then(m => m.default || m)
  }
],
    name: authlzvdI7r9k7Meta?.name ?? undefined,
    meta: authlzvdI7r9k7Meta || {},
    alias: authlzvdI7r9k7Meta?.alias || [],
    redirect: authlzvdI7r9k7Meta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: index0wQsjFcazOMeta?.name ?? "docs",
    path: index0wQsjFcazOMeta?.path ?? "/docs",
    meta: index0wQsjFcazOMeta || {},
    alias: index0wQsjFcazOMeta?.alias || [],
    redirect: index0wQsjFcazOMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    path: evaluationvzGEGtRvB2Meta?.path ?? "/evaluation",
    children: [
  {
    name: indexmcUy4IGQJzMeta?.name ?? "evaluation-id",
    path: indexmcUy4IGQJzMeta?.path ?? ":id()",
    meta: indexmcUy4IGQJzMeta || {},
    alias: indexmcUy4IGQJzMeta?.alias || [],
    redirect: indexmcUy4IGQJzMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/evaluation/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmHKzKHpKihLMeta?.name ?? "evaluation-confirm",
    path: confirmHKzKHpKihLMeta?.path ?? "confirm",
    meta: confirmHKzKHpKihLMeta || {},
    alias: confirmHKzKHpKihLMeta?.alias || [],
    redirect: confirmHKzKHpKihLMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/evaluation/confirm.vue").then(m => m.default || m)
  },
  {
    name: historySxSQcYM4Y3Meta?.name ?? "evaluation-history",
    path: historySxSQcYM4Y3Meta?.path ?? "history",
    meta: historySxSQcYM4Y3Meta || {},
    alias: historySxSQcYM4Y3Meta?.alias || [],
    redirect: historySxSQcYM4Y3Meta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/evaluation/history.vue").then(m => m.default || m)
  },
  {
    name: indexbQQ6z7cmgYMeta?.name ?? "evaluation",
    path: indexbQQ6z7cmgYMeta?.path ?? "",
    meta: indexbQQ6z7cmgYMeta || {},
    alias: indexbQQ6z7cmgYMeta?.alias || [],
    redirect: indexbQQ6z7cmgYMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/evaluation/index.vue").then(m => m.default || m)
  }
],
    name: evaluationvzGEGtRvB2Meta?.name ?? undefined,
    meta: evaluationvzGEGtRvB2Meta || {},
    alias: evaluationvzGEGtRvB2Meta?.alias || [],
    redirect: evaluationvzGEGtRvB2Meta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/evaluation.vue").then(m => m.default || m)
  },
  {
    name: indexovMrFcLKw4Meta?.name ?? "index",
    path: indexovMrFcLKw4Meta?.path ?? "/",
    meta: indexovMrFcLKw4Meta || {},
    alias: indexovMrFcLKw4Meta?.alias || [],
    redirect: indexovMrFcLKw4Meta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlGgmNHQ6aqMeta?.name ?? "user",
    path: indexlGgmNHQ6aqMeta?.path ?? "/user",
    meta: indexlGgmNHQ6aqMeta || {},
    alias: indexlGgmNHQ6aqMeta?.alias || [],
    redirect: indexlGgmNHQ6aqMeta?.redirect || undefined,
    component: () => import("/projects/frontend/src/pages/user/index.vue").then(m => m.default || m)
  }
]