<script setup lang="ts"></script>

<template>
  <footer
    class="flex w-full items-center justify-center bg-black-squeeze px-4 pb-16 pt-8"
    v-bind="$attrs"
  >
    <div
      class="flex w-full max-w-[1366px] flex-col items-center justify-center text-[#666666] lg:flex-row lg:gap-36"
    >
      <NuxtLink :to="{ name: 'index' }">
        <img
          src="/logo.svg"
          alt="Логотип FoxtailBox (Фокстейл бокс)"
          class="h-[31px] w-[134px] md:h-[46px] md:w-[198px]"
        />
      </NuxtLink>
      <nav class="mt-8 flex grid-cols-2 flex-col items-center gap-4 text-base md:grid md:gap-x-10">
        <a href="https://api.dev.foxtailbox.com/storage/pdf/privacy_agreement.pdf" target="_blank"
          >Политика конфиденциальности</a
        >
        <a href="mailto:support@foxtailbox.com">Обратная связь</a>
        <a href="https://api.dev.foxtailbox.com/storage/pdf/user_agreement.pdf" target="_blank"
          >Пользовательское соглашение</a
        >
        <NuxtLink :to="{ name: 'docs' }">Документация</NuxtLink>
      </nav>
      <a
        href="https://www.fasie.ru/"
        target="_blank"
        class="mt-6 flex flex-col items-center justify-center"
      >
        <p class="mb-3 text-sm text-black lg:text-base">При поддержке</p>
        <img src="/fpi_logo.svg" alt="Фонд содействия инновациям" class="h-[54px] w-[120px]" />
      </a>
    </div>
  </footer>
</template>

<style scoped></style>
