/**
 * Загрузка первоначальных данных при запуске сервиса
 */
export default defineNuxtPlugin(async () => {
  try {
    const { isAuthorized, fetchAuthUser } = useUserStore()
    if (isAuthorized) {
      await fetchAuthUser()
    }
  } catch (e) {
    console.error(e)
  }
})
