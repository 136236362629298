<script setup lang="ts">
import { IHeaderLink } from '~/components/infrastructure/header/types'
import { NuxtLink } from '#components'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

defineProps<{
  links: IHeaderLink[]
  close: any
}>()

// Composable
const userStore = useUserStore()

// Data
const { isAuthorized } = storeToRefs(userStore)

const logOut = async () => {
  await userStore.logOut()
  await navigateTo({ name: 'auth' })
}
</script>

<template>
  <button class="flex w-full text-mine-shaft">
    <div v-if="isAuthorized" class="flex w-full items-center gap-3">
      <Disclosure v-slot="menu" as="button" class="flex w-full flex-col">
        <DisclosureButton
          class="w-full py-3 pl-4 text-left transition duration-300"
          :class="[{ 'bg-[#F1F1F1]': menu.open }]"
        >
          Меню
        </DisclosureButton>
        <AnimateTransition enter-name="slideInLeft" out-name="fadeOut" speed="faster">
          <DisclosurePanel class="flex w-full flex-col items-start" @click="close">
            <NuxtLink :to="{ name: 'user' }" class="w-full">
              <DisclosureButton class="w-full py-2 pl-8 text-left">
                Личный кабинет
              </DisclosureButton>
            </NuxtLink>
            <DisclosureButton class="w-full py-2 pl-8 text-left" @click="logOut">
              Выйти
            </DisclosureButton>
          </DisclosurePanel>
        </AnimateTransition>
      </Disclosure>
    </div>
    <NuxtLink
      v-if="!isAuthorized"
      :to="{ name: 'auth' }"
      class="flex w-full items-center gap-3 py-3 pl-4"
      @click="close"
    >
      Войти
    </NuxtLink>
  </button>
</template>
