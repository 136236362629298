<script setup lang="ts">
const error = useError()
const status = computed(() =>
  error.value && 'statusCode' in error.value ? error.value?.statusCode : ''
)
const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div class="flex w-full flex-col items-center justify-center gap-7 px-5 py-10 md:px-0">
    <h1 class="text-9xl font-bold">{{ status }}</h1>
    <div class="flex max-w-[400px] flex-col items-center text-center">
      <p class="mb-4 text-headline font-bold">Необработанная ошибка</p>
      <p class="mb-1 leading-5">{{ error?.message }}</p>

      <p class="leading-5">
        Сообщите о проблеме на почту
        <a class="hover:underline" href="mailto:support@foxtailbox.com">support@foxtailbox.com</a>.
      </p>
      <Button class="mt-6 px-5 py-3" @click="handleError">Вернуться на главную</Button>
    </div>
  </div>
</template>

<style scoped></style>
