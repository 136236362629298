<script setup lang="ts">
const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div class="flex w-full flex-col items-center justify-center gap-7 px-5 py-10 md:px-0">
    <img src="/images/403.svg" alt="404" />
    <div class="flex max-w-[400px] flex-col items-center text-center">
      <p class="mb-4 text-headline font-bold">Доступ запрещен</p>
      <p class="mb-1 leading-5">К сожалению, у вас нет разрешения на доступ к этой странице.</p>
      <p class="leading-5">
        Если вы считаете, что у вас должны быть права доступа, пожалуйста, свяжитесь с нами по
        адресу
        <a class="hover:underline" href="mailto:support@foxtailbox.com">support@foxtailbox.com.</a>,
        чтобы получить дополнительную помощь.
      </p>
      <Button class="mt-6 px-5 py-3" @click="handleError">Вернуться на главную</Button>
    </div>
  </div>
</template>

<style scoped></style>
