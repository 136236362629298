/**
 * Sentry для отслеживания ошибок сервиса
 */
import { init, BrowserTracing, vueRouterInstrumentation } from '@sentry/vue'

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig()
  const dns = config.public.sentryDns

  if (dns) {
    init({
      app: nuxtApp.vueApp,
      dsn: dns,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(useRouter()),
        }),
      ],
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      tracesSampleRate: 1.0,
    })
  }
})
