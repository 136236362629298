<script setup lang="ts">
/**
 * Обертка для модальных окон
 */

import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ModalContextKey, IModalType, ModalTypes } from '~/components/infrastructure/modal/types'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    type?: IModalType
  }>(),
  {
    type: ModalTypes.ADAPTIVE,
  }
)

const emit = defineEmits<{
  (event: 'update:modelValue', val: boolean): void
  (event: 'close'): void
  (event: 'ok', data: any): void
  (event: 'dismiss'): void
}>()

const onClose = () => {
  emit('update:modelValue', false)
  emit('close')
}

const onDismiss = () => {
  emit('update:modelValue', false)
  emit('dismiss')
}

const onOk = (data: any) => {
  emit('update:modelValue', false)
  emit('ok', data)
}

provide(ModalContextKey, {
  onClose,
  onDismiss,
  onOk,
  type: computed(() => props.type),
})

const isAdaptive = computed(() => props.type === ModalTypes.ADAPTIVE)
</script>

<template>
  <ClientOnly>
    <TransitionRoot appear :show="modelValue" as="template">
      <Dialog ref="el" as="div" class="relative z-20" v-bind="$attrs" @close="onClose">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0"
            :class="[isAdaptive ? 'bg-white md:bg-[#1c1c1e4d]' : 'bg-[#1c1c1e4d]']"
            aria-hidden="true"
          />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto" :class="[isAdaptive ? 'pt-16 md:pt-0' : 'mx-5']">
          <div class="flex min-h-full items-center justify-center text-center md:p-4">
            <DialogPanel as="div" class="flex w-full flex-col items-center justify-center">
              <Header v-if="isAdaptive" class="left-0 md:hidden" />
              <TransitionChild
                as="template"
                enter="duration-500 md:duration-300 ease-out"
                :enter-from="`opacity-0 ${
                  isAdaptive ? '-translate-x-40 md:translate-x-0 md:scale-95' : 'scale-95'
                }`"
                :enter-to="`opacity-100 ${isAdaptive ? 'translate-x-0 md:scale-100' : 'scale-100'}`"
                leave="duration-200 ease-in"
                :leave-from="`opacity-100 ${
                  isAdaptive ? 'translate-x-0 md:scale-100' : 'scale-100'
                }`"
                :leave-to="`opacity-0 ${
                  isAdaptive ? '-translate-x-40 md:translate-x-0 md:scale-95' : 'scale-95'
                }`"
              >
                <slot />
              </TransitionChild>
              <Footer v-if="isAdaptive" class="md:hidden" />
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </ClientOnly>
</template>
